import imgBrazil from "@/assets/img/img-Brasil.jpeg";
import imgEspanish from "@/assets/img/img-Espanha.png";

import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";

import useJwt from "@/auth/jwt/useJwt";

const FLAG_PTBR = "pt-BR";
const INVALID_EMAIL = "INVALID_EMAIL";
const INVALID_REQUIRED = "INVALID_REQUIRED";

extend("email", {
  ...email,
  message: INVALID_EMAIL,
});

extend("required", {
  ...required,
  message: INVALID_REQUIRED,
});

export default {
  name: "Login",
  layout: "empty",
  metaInfo: {
    title: `${process.env.VUE_APP_COMPANY_NAME} - Login`,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      busy: false,
      locale: this.$i18n.locale,
      user: {},
      textDropdown: "Português",
      imgFlagEspanish: imgEspanish,
      imgFlagBrazil: imgBrazil,
      imgFlag: imgBrazil,

      loginErro: "",
    };
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;

      if (FLAG_PTBR === val) {
        this.textDropdown = this.$t("languages.ptBR");
      } else {
        this.textDropdown = this.$t("languages.es");
      }
    },
  },
  methods: {
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
    login() {
      this.busy = true;
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.user.email,
              senha: this.user.senha,
            })
            .then((response) => {
              const { contaSelecionada } = response.data;
              useJwt.setToken(response.data.token);
              localStorage.setItem(
                useJwt.jwtConfig.storageUserDataName,
                JSON.stringify(contaSelecionada)
              );
              this.busy = false;
              this.$router.replace("/dashboard");
            })
            .catch(() => {
              this.$bvToast.toast(this.$t("sectionLogin.errorLogin"), {
                title: this.$t("sectionLogin.errorTitleLogin"),
                variant: "danger",
                solid: true,
              });

              this.busy = false;
            });
        }
      });

      this.busy = false;
    },
    myErrors(e) {
      if (INVALID_EMAIL === e) {
        return "Digite um e-mail válido";
      }

      return "Digite uma senha";
    },
    changeTextDropdown(language, flag, img) {
      this.locale = flag;
      this.imgFlag = img;
    },
  },
};
